<template>
	<div>
		<el-card class="box-card">
			<div slot="header" class="clearfix">
				<span>评论列表</span>
			</div>
			<operate-search :isSuper="false" @search="search" placeholder="请输入客户名称">
				<template #operateButtons>
					<el-button type="primary" size="small" @click="showForm(-1)">新增评论</el-button>
				</template>
			</operate-search>
			<el-table ref="multipleTable" :data="commentList" border tooltip-effect="dark" class="mt-3">
				<el-table-column type="index" width="50" label="序号">
				</el-table-column>
				<el-table-column prop="username" label="客户名称" width="100">
				</el-table-column>
				<el-table-column prop="service_id" label="服务ID">
				</el-table-column>
				<el-table-column prop="comment" label="评论">
				</el-table-column>
				<el-table-column prop="level" label="星级">
				</el-table-column>
				<el-table-column prop="show" label="公开服务">
					<template slot-scope="scope">
						<span>{{scope.row.is_show == 1 ? '公开':'不公开'}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="status" label="状态" width="100">
					<template slot-scope="scope">
						<el-switch v-model="scope.row.status" active-color="#13ce66" inactive-color="#ff4949"
							:active-value="1" :inactive-value="0" @change="changeStatus(scope.row)">
						</el-switch>
					</template>
				</el-table-column>
				<el-table-column prop="create_time" label="创建时间" width="100">
				</el-table-column>
				<el-table-column prop="operate" label="操作" width="200">
					<template slot-scope="scope">
						<el-button type="text" size="mini" @click="showForm(scope.$index, scope.row)">修改</el-button>
						<el-button type="text" size="mini" @click="deleteItem(scope.row)">
							删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination @size-change="pageSizeChange" @current-change="pageCurrentChange"
				:current-page="pageData.current" :page-sizes="pageData.sizes" :page-size="pageData.size"
				layout="total, sizes, prev, pager, next, jumper" :total="pageData.total" class="mt-3">
			</el-pagination>
		</el-card>
		<el-dialog :title="formTitle" :visible.sync="formVisible">
			<el-form :model="formData" :rules="commentRules" ref="commentRuleForm">
				<el-form-item label="客户名称" :label-width="formLabelWidth" prop="username">
					<el-input v-model="formData.username" autocomplete="off" size="small" style="width: 80%;">
					</el-input>
				</el-form-item>
				<el-form-item label="评论" :label-width="formLabelWidth" prop="comment">
					<el-input v-model="formData.comment" autocomplete="off" size="small" style="width: 80%;">
					</el-input>
				</el-form-item>
				<el-form-item label="星级" :label-width="formLabelWidth" prop="level">
					<div style="width: 80%;">
						<el-rate
						  v-model="formData.level"
						  :texts="rateTexts"
						  show-text>
						</el-rate>
					</div>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="formVisible = false">取 消</el-button>
				<el-button type="primary" @click="submit">确 定</el-button>
			</div>
		</el-dialog>
		<product-dialog ref="productDialog" @selectConfirm="bindWeChat"></product-dialog>
	</div>
</template>

<script>
	import operateSearch from "@/components/common/operate-search.vue"
	import common from "@/common/mixins/common.js"
	import productDialog from '@/components/common/product-dialog.vue'
	export default {
		components: {
			operateSearch,productDialog
		},
		inject: ['layout'],
		mixins: [common],
		data() {
			return {
				preUrl: 'comment',
				commentList: [],
				keyword: '',
				editIndex: -1,
				formTitle: '添加评论',
				formVisible: false,
				formData: {},
				formLabelWidth: '120px',
				selectIndex:'',
				commentRules: {
					username: [{
						required: true,
						message: '请输入客户名称',
						trigger: 'blur'
					}, ],
					comment: [{
						required: true,
						message: '请输入评论内容',
						trigger: 'blur'
					}, ],
				},
				rateTexts:['极差','差','一般','好','极好']
			};
		},
		watch:{
			formVisible:function(){
				if(!this.formVisible){
					this.$refs['commentRuleForm'].resetFields();
				}
			}
		},
		methods: {
			getListRes(e) {
				this.commentList = e.list
			},
			getUrl() {
				let url = `/admin/${this.preUrl}/index/${this.pageData.current}?limit=${this.pageData.size}`
				if (this.keyword !== '') {
					url += '&keyword=' + this.keyword
				}
				return url
			},
			showForm(index, row) {
				this.editIndex = index
				if (this.editIndex === -1) {
					this.formTitle = '添加评论'
					this.formData = {
						username: '',
						comment: '',
						level: '',
						status:1
					}
				} else {
					this.formTitle = '修改评论'
					console.log(row)
					this.formData = {
						username: row.username,
						comment: row.comment,
						level: row.level,
						status:row.status
					}
				}
				this.formVisible = true
			},
			submit() {
				this.$refs['commentRuleForm'].validate((valid) => {
					if (valid) {
						let id = 0
						if (this.editIndex !== -1) {
							id = this.commentList[this.editIndex].id
						}
						this.addOrEdit(this.formData, id)
						this.formVisible = false
					}
				})
			},
			search(e) {
				this.keyword = e
				this.getList()
			},
			showProductDialog(index) {
				this.selectIndex = index
				this.$refs.productDialog.visibleDialog = true
				this.$refs.productDialog.getListData()
			},
			changeStatus(data) {
				let message = data.status ? '评论显示' : '评论隐藏'
				this.axios.post(`/admin/${this.preUrl}/updatestatus/${data.id}`, {
						status: data.status
					}, {
						token: true
					})
					.then((res) => {
						this.$message({
							message: message,
							type: 'success'
						})
					})
					.catch((err) => {
						console.log(err)
					})
			},
		}
	};
</script>

<style>
</style>
