<template>
	<div>
		<el-dialog :title="title" :visible.sync="visibleDialog" top="5vh"
			@close="cancel">
			<el-container style="height: 400px; border: 1px solid #eee">
				<el-container>
					<el-header class="d-flex align-items-center justify-content-end border-bottom" style="background-color: #FFFFFF;">
						<el-form ref="searchForm" :inline="true" :model="searchData" label-width="80px">
							<el-form-item class="mt-1 mb-0" label="关键字">
								<el-input size="small" v-model="searchData.keyword" placeholder="请输入关键字" clearable></el-input>
							</el-form-item>
							<el-form-item class="mt-1 mb-0">
								<el-button size="small" type="primary" icon="el-icon-search" @click="searchClick">搜索</el-button>
							</el-form-item>
						</el-form>
					</el-header>

					<el-main style="padding: 0px;position: relative;">
						<el-table :data="productData" v-loading="loading">
							<el-table-column type="index" width="50" label="序号">
							</el-table-column>
							<el-table-column prop="avatarUrl" label="微信头像" width="80">
								<template slot-scope="scope">
									<el-avatar size="large" :src="scope.row.avatarUrl"></el-avatar>
								</template>
							</el-table-column>
							<el-table-column prop="nickName" label="微信昵称">
							</el-table-column>
							<el-table-column prop="operate" label="操作" width="200">
								<template slot-scope="scope">
									<el-button type="primary" plain size="mini" @click="selectConfirm(scope.row)">绑定</el-button>
								</template>
							</el-table-column>
						</el-table>
					</el-main>
					
					<el-footer class="d-flex justify-content-center align-items-center border-top">
						<el-pagination @size-change="sizeChange" @current-change="currentChange" :current-page="pageData.currentPage" :page-sizes="pageData.sizes"
							:page-size="pageData.size" layout="total, sizes, prev, pager, next, jumper"
							:total="pageData.total">
						</el-pagination>
					</el-footer>
				</el-container>
			</el-container>
			<!-- <div slot="footer" class="dialog-footer">
				<el-button @click="visibleDialog = false" size="small">取 消</el-button>
				<el-button type="primary" @click="confirm" size="small">确 定</el-button>
			</div> -->
		</el-dialog>
	</div>
</template>

<script>
	export default {
		props:{
			title:{
				type:String,
				default:'请选择'
			}
		},
		data() {
			return {
				visibleDialog: false,
				loading:false,
				productData: [],
				searchData:{
					keyword: ''
				},
				radio:'',
				pageData: {
					currentPage: 1,
					sizes: [10, 20, 50, 100],
					size: 10,
					total: 100
				}
			}
		},
		methods: {
			getListData() {
				let url = '/admin/user/index/'+this.pageData.currentPage+'?limit='+this.pageData.size
				for (let key in this.searchData) {
					let value = this.searchData[key]
					if(this.searchData[key]) {
						url += '&' + key + '=' + value
					}
				}
				this.loading = true
				this.axios.get(url,{token:true})
				.then((res)=>{
					let data = res.data.data
					this.productData = data.list
					this.pageData.total = data.totalCount
					this.loading = false
				})
			},
			searchClick() {
				this.pageData.currentPage = 1
				this.getListData()
			},
			sizeChange(val) {
				this.pageData.size = val
				this.getListData()
			},
			currentChange(val) {
				this.pageData.currentPage = val
				this.getListData()
			},
			selectConfirm(row) {
				this.$confirm('是否绑定该微信用户?', '确认', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'success'
				}).then(() => {
					this.$emit('selectConfirm',row)
					this.visibleDialog = false
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消'
					});
				});
			},
			cancel() {
				this.productData = []
				this.searchData = {
					keyword: '',
				}
				this.pageData.total = 0
			}
		}
	}
</script>

<style>
	.el-dialog__body {
		padding: 10px 20px;
	}
</style>
